@use "variables";

// Stacked Icons
// -------------------------

.#{variables.$fa-css-prefix}-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.#{variables.$fa-css-prefix}-stack-1x, .#{variables.$fa-css-prefix}-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.#{variables.$fa-css-prefix}-stack-1x { line-height: inherit; }
.#{variables.$fa-css-prefix}-stack-2x { font-size: 2em; }
.#{variables.$fa-css-prefix}-inverse { color: variables.$fa-inverse; }
