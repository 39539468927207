@use "variables";

// Spinning Icons
// --------------------------

.#{variables.$fa-css-prefix}-spin {
  animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.#{variables.$fa-css-prefix}-pulse {
  animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
