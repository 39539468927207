@use "variables";

@use 'sass:math';

// Fixed Width Icons
// -------------------------
.#{variables.$fa-css-prefix}-fw {
  text-align: center;
  width: math.div(18em, 14);
}
