@use "variables";

/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  //  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  src: url('#{variables.$fa-font-path}/fontawesome-webfont.woff2?v=#{variables.$fa-version}') format('woff2'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.woff?v=#{variables.$fa-version}') format('woff'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.ttf?v=#{variables.$fa-version}') format('truetype'),
    url('#{variables.$fa-font-path}/fontawesome-webfont.svg?v=#{variables.$fa-version}#fontawesomeregular') format('svg');
}
