// Fixes for react-datepicker v4.8.0
// See https://github.com/Hacker0x01/react-datepicker/issues/3672

.react-datepicker__navigation--years::before {
  border-color: #666;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  left: 11px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation--years-upcoming::before {
  top: 17px;
  transform: rotate(315deg);
}

.react-datepicker__navigation--years-previous::before {
  top: 6px;
  transform: rotate(135deg);
}
