@use "sass:color";
$background-header: #000;
$gray-lighter: #f9f9f9;
$gray-light: #f1f1f1;
$gray: #ccc;
$gray-eee: #eee;
$gray-dark: #999;
$gray-darker: #666;
$dimgray: #696969;
$black: #000;
$white: #fff;
$link-blue: #428bca;
$transparent: transparent;
$primary: #337ab7;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #a94442;
$verified: #008000;

$alert-warning: #856404;
$alert-warning-bg: #fff3cd;
$alert-warning-border: #ffeeba;
$alert-warning-hover: color.adjust($alert-warning-bg, $lightness: -15%);

$alert-success: #155724;
$alert-success-bg: #d4edda;
$alert-success-border: #c3e6cb;
$alert-success-hover: color.adjust($alert-success-bg, $lightness: -15%);

$alert-info: #31708f;
$alert-info-bg: #d9edf7;
$alert-info-border: #bce8f1;
$alert-info-hover: color.adjust($alert-info-bg, $lightness: -15%);

$alert-primary: #004085;
$alert-primary-bg: #cce5ff;
$alert-primary-border: #b8daff;
$alert-primary-hover: color.adjust($alert-primary-bg, $lightness: -15%);

$alert-danger: #721c24;
$alert-danger-bg: #f8d7da;
$alert-danger-border: #f5c6cb;
$alert-danger-hover: color.adjust($alert-danger-bg, $lightness: -15%);

$link-blue-hover: #23527c;

$primary-bg: $primary;
$primary-border: color.adjust($primary-bg, $lightness: -5%);
$primary-hover: color.adjust($primary-bg, $lightness: -15%);
$primary-border-hover: color.adjust($primary-border, $lightness: -15%);

$success-bg: $success;
$success-border: color.adjust($success-bg, $lightness: -5%);
$success-hover: color.adjust($success-bg, $lightness: -15%);
$success-border-hover: color.adjust($success-border, $lightness: -15%);

$info-bg: $info;
$info-border: color.adjust($info-bg, $lightness: -5%);
$info-hover: color.adjust($info-bg, $lightness: -15%);
$info-border-hover: color.adjust($info-border, $lightness: -15%);

$warning-bg: $warning;
$warning-border: color.adjust($warning-bg, $lightness: -5%);
$warning-hover: color.adjust($warning-bg, $lightness: -15%);
$warning-border-hover: color.adjust($warning-border, $lightness: -15%);

$danger-bg: $danger;
$danger-border: color.adjust($danger-bg, $lightness: -5%);
$danger-hover: color.adjust($danger-bg, $lightness: -15%);
$danger-border-hover: color.adjust($danger-border, $lightness: -15%);

$gray-5p: color.adjust($gray, $lightness: -5%);
$gray-10p: color.adjust($gray, $lightness: -10%);
$gray-15p: color.adjust($gray, $lightness: -15%);
$gray-20p: color.adjust($gray, $lightness: -20%);

$report-title-color: #2f2b7f;
