@use 'artp_colors';
@use 'fontawesome/font-awesome';
@use 'fix/react_datepicker_navigation';

body {
  --common-gray: #f1f1ef;
  --color-active-or-hover: #d9d3c3;
  --color-active-or-hover-border: #c1b79d;
}

.wide-screen {
  @media(min-width: 1400px) {
    .container {
      width: 1320px;
    }
  }

  @media(min-width: 1600px) {
    .container {
      width: 1520px;
    }
  }

  @media(min-width: 2000px) {
    .container {
      width: 1960px;
    }
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0, 0);
  }

  20% {
    opacity: 1;
    transform: scale(25, 25);
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

@-moz-document url-prefix('') {
  select.form-control {
    appearance: none;
    background: artp_colors.$white url('data:image/svg+xml, <svg version= "1.1" xmlns= "http://www.w3.org/2000/svg" width= "14px" height= "12px" viewBox="0 0 1792 1792" fill= "%23333" ><path d=" M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z "/></svg>') no-repeat calc(100% - 7px) 50%;
    padding-right: 25px;
  }
}

html {
  background-color: #fff;
  height: 100%;
}

body {
  background-color: inherit;
  color: #000;
  height: 100%;
  padding: 0;
  position: relative;

  .body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .site-content {
      flex: 1 1 auto;
      position: relative;
    }
  }
}

#main-title {
  align-items: center;
  background-color: #fafafa;
  display: flex;
  height: 100px;
  justify-content: center;

  h2 {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    height: 40px;
    margin-bottom: 10px;
  }
}

.fa-wechat {
  color: #1aad19;
}

.btn-flat {
  border: 0;
  color: artp_colors.$gray-dark;
  font-size: 16px;
  overflow: hidden;
  position: relative;

  &.link {
    color: #337ab7;

    &::after {
      background: rgba(200, 200, 200, 0.35);
    }
  }

  &.btn-sm {
    font-size: 12px;
  }

  &.btn-xs {
    font-size: 12px;
  }

  &::after {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    content: '';
    height: 5px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
    width: 5px;
  }

  &:focus:not(:active)::after,
  &.ripple-on-hover:hover::after {
    animation: ripple 1s ease-out;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.help-block-container {
  background-color: artp_colors.$gray-light;
  border-radius: 5px;
  box-shadow: 2px 2px 5px artp_colors.$background-header;
  padding: 10px;
  position: absolute;
  right: 0;
  width: 50%;
  z-index: 100;

  @media (max-width: 768px) {
    right: 10px;
    width: 75%;
  }

  &.help-block-container-no-bg {
    background-color: artp_colors.$white;
    border: 0;
    box-shadow: none;
    padding-top: 20px;
  }

  &.help-block-price {
    margin-top: -45px;
  }
}

.page-break {
  clear: both;
  display: block;
  page-break-after: always;
  page-break-inside: avoid;
}

.feedback-select2 {
  right: 30px !important;
}

#at4-follow {
  z-index: 50;
}

.modal-content {
  .modal-header {
    min-height: 40px;
  }

  &.notify {
    button {
      &.close {
        background-color: artp_colors.$white;
        border-radius: 10px;
        opacity: 0.5;
        padding: 3px;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.time-picker {
  margin-top: -22px;

  .btn-link {
    padding: 0;
  }

  input {
    font-size: 12px;
  }
}

.date-picker {
  ul {
    li {
      min-width: 260px;
      padding: 5px;
    }
  }
}

.subscribers_only {
  color: artp_colors.$gray;
  font-style: italic;
  font-weight: bold;
}

.nav-pills-custom {
  font-size: 13px;
  margin-top: 10px;
  min-height: inherit;

  li {
    margin-right: 5px;

    @media (max-width: 992px) {
      margin-bottom: 10px;
    }

    .pagination {

      margin-bottom: 0;

      @media (max-width: 992px) {
        margin-bottom: 10px;
      }

      .page,
      .prev,
      .first,
      .next,
      .next_page {
        a {
          background-color: artp_colors.$gray-darker;
          border: 0;
          color: artp_colors.$white;

        }

        &.active {
          a {
            background-color: artp_colors.$gray-light;
            border-color: artp_colors.$gray;
            box-shadow: inset 0 3px 5px artp_colors.$background-header;
            color: artp_colors.$gray-darker;
          }
        }
      }
    }

    .btn-group {
      @media (max-width: 992px) {
        float: right;
      }

      .btn {
        background-color: artp_colors.$gray-darker;
        color: artp_colors.$white;

        &.active {
          background-color: artp_colors.$gray-light;
          color: artp_colors.$gray-darker;
        }
      }
    }

    &.info {
      font-weight: bold;
      padding-top: 5px;
    }

    &.dropdown {
      background-color: artp_colors.$gray-darker;
      border-radius: 3px;
      margin-right: 3px;

      &.open a {
        background-color: artp_colors.$gray-darker;
      }

      a {
        color: artp_colors.$white;
        padding: 5px 10px;

        &:hover {
          background-color: artp_colors.$gray-darker;
        }
      }

      ul {
        background-color: artp_colors.$gray-darker;

        li {
          margin-right: 0;

          a {
            color: artp_colors.$white;
            line-height: 1;
            padding: 5px 10px;

            &:hover {
              background-color: artp_colors.$gray-darker;
            }
          }
        }
      }
    }
  }
}

.artprice-page-header {
  background-color: artp_colors.$background-header;
  box-sizing: border-box;
  color: artp_colors.$white;
  height: 76px;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 5px;

  .modal-content {
    color: artp_colors.$black;
  }

  .jd_menu {
    float: left;
    width: 100%;
  }

  .inside {
    margin-left: auto;
    margin-right: auto;
    width: 996px;

    #logo {
      float: left;
      height: 40px;
      width: 158px;
    }

    #headline {
      color: artp_colors.$white;
      float: left;
      font-size: 12px;
      font-weight: bold;
      padding-top: 16px;
      width: 455px;
    }

    #block-login {
      float: right;
      padding-top: 10px;
    }
  }
}

.form-feedback {
  input {
    &.ng-invalid {
      background-color: artp_colors.$white;
      border-color: artp_colors.$gray;
    }
  }

  .select2-container.ng-invalid {
    .select2-chosen {
      background-color: artp_colors.$white;

    }

    .select2-choice {
      background-color: artp_colors.$white none;
      color: artp_colors.$gray;
    }
  }

}

.field_with_errors {
  background-color: artp_colors.$danger-bg;
  border-color: artp_colors.$danger-border;
}

a {
  img {
    border: 0;
  }
}

.phoneline {
  float: left;
  margin-left: 15px;
  width: 100%;
}

.ail_img {
  cursor: pointer;
  margin: auto;
}

.bottom-amci {
  background: artp_colors.$gray-light;
  border: solid 1px artp_colors.$gray-light;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
}

// CLASSES CSS COMMUNES A L'APPLICATION

#cookies_banner {
  background-color: artp_colors.$gray-light;
  bottom: 0 !important;
  padding: 5px 10px;
  position: fixed;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  z-index: 110;

  .text {
    height: 100%;
  }

  .more {
    margin-left: 5px;
  }

  .linkbutton {
    margin-left: 5px;
  }
}

.javascript_warning {
  background: artp_colors.$warning no-repeat 13px center;
  border-bottom: 1px solid artp_colors.$warning-border;
  color: artp_colors.$black;
  cursor: pointer;
  font-size: 12px;
  left: 0;
  text-align: left;
  top: 0;
  width: 100%;
  z-index: 111111;

  div {
    padding: 5px 36px 5px 40px;

  }
}

.fileinput-button {
  overflow: hidden;
  position: relative;

  input {
    cursor: pointer;
    direction: ltr;
    filter: alpha(opacity=0);
    font-size: 23px;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-300px, 0) scale(4);
  }
}

#header {
  background-color: artp_colors.$background-header;
  border-radius: 0;

  .container {
    margin-top: 5px;
  }

  #header_section {
    color: artp_colors.$white;
    font-size: 16px;
    font-weight: bold;
    vertical-align: bottom;
  }

  #login_encart {
    padding-right: 5px;

    .dropdown-menu {
      z-index: 10000;
    }

    .error_login {
      display: block;
      margin-top: 2px;
      text-align: center;
    }
  }
}

.breadcrumb {
  background-color: artp_colors.$white;
  border: 0;
  font-size: 11px;
  margin-bottom: 0;
  padding: 7px 5px;
  text-align: left;
}

@mixin flag-base {
  background: url('../images/flags.png') no-repeat;
  height: 11px;
  width: 16px;
}

.flag-ci {
  @include flag-base;
  background-position: -16px 0;
}

.flag-zh {
  @include flag-base;
  background-position: -32px 0;
}

.flag-de {
  @include flag-base;
  background-position: 0 -11px;
}

.flag-es {
  @include flag-base;
  background-position: -16px -11px;
}

.flag-fr {
  @include flag-base;
  background-position: -32px -11px;
}

.flag-en {
  @include flag-base;
  background-position: 0 -22px;
}

.flag-it {
  @include flag-base;
  background-position: -16px -22px;
}

.footer {
  font-size: 0.8em;

  .navbar-nav > li > a {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .copyright {
    color: artp_colors.$gray-dark;
    font-weight: normal;
    text-align: center;

    a {
      color: artp_colors.$gray-dark;
      text-decoration: none;
    }
  }
}

#base-modal {
  .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-footer {
    margin-top: 0;
  }
}

.modal-backdrop.in {
  opacity: 0.85;
}

#artpricecgv {
  font-size: 0.9em;
  padding: 5px;
  text-align: left;

  p {
    text-align: justify;
  }

  .cgvtitle {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }

  .cgvarticle {
    font-weight: bold;
    margin-top: 10px;
  }

  .cgvaddress {
    margin-left: 20px;
  }

}

#artpricecgv_overlay {
  font-size: 12px;
  height: 540px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  text-align: left;
  width: 608px;

  p {
    text-align: justify;
  }

  .cgvtitle {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }

  .cgvarticle {
    font-weight: bold;
    margin-top: 10px;
  }

  .cgvaddress {
    margin-left: 20px;
  }

  .form-control-artprice {
    &.label-danger {
      background-color: inherit;
      color: artp_colors.$danger;
    }
  }
}

.promote-sign-in-banner {
  cursor: pointer;
  font-size: 16px;
  margin-top: 0;
  padding: 5px 0;

  &.promote-sign-in-banner-artist {
    border: solid 1px #f1f1f1;
    box-shadow: 2px 2px 5px #f1f1f1;
    margin-bottom: 20px;
  }

  .new-comer {
    display: flex;
    justify-content: center;
    padding: 5px 0;
  }

  p {
    color: #666;
    font-size: 26px;
    padding-top: 10px;
    text-align: center;
  }
}

.amciup,
.amcidown {
  white-space: nowrap;
}


.custom-panel {
  border: solid 1px #ddd;
  border-radius: 4px;

  .custom-panel-cell {
    border-top: solid 1px #ddd;
    padding: 5px 10px;
  }

  .custom-panel-cell-header {
    background-color: #f5f5f5;
    padding: 5px 10px;

    &.custom-panel-cell-header-pdf {
      background-color: #fff;
    }
  }
}

.fa.fa-chevron-right {
  width: 7.5px;
}

.wechat-qr-code {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: all linear 0.5s;
  width: 100vw;
  z-index: 100000;

  &.ng-hide {
    opacity: 0;
  }

  &.ng-show {
    opacity: 1;
  }

  img {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    max-width: 260px;
  }
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    appearance: none;
    height: 5px;
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.line-through {
  text-decoration: line-through;
}

.display-block {
  display: block;
}

/* fastmail specific classes (to hide some mailing sections in private messages) */
.artp-em {
  padding-bottom: 45px;

  .artp-em-hide {
    display: none;
  }
}
