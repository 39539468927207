@use "variables";

// Bordered & Pulled
// -------------------------

.#{variables.$fa-css-prefix}-border {
  border: solid 0.08em variables.$fa-border-color;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.#{variables.$fa-css-prefix}-pull-left { float: left; }
.#{variables.$fa-css-prefix}-pull-right { float: right; }

.#{variables.$fa-css-prefix} {
  &.#{variables.$fa-css-prefix}-pull-left { margin-right: 0.3em; }
  &.#{variables.$fa-css-prefix}-pull-right { margin-left: 0.3em; }
}

/* Deprecated as of 4.4.0 */
.pull-right { float: right; }
.pull-left { float: left; }

.#{variables.$fa-css-prefix} {
  &.pull-left { margin-right: 0.3em; }
  &.pull-right { margin-left: 0.3em; }
}
